import React from "react";
import { graphql } from "gatsby";
import { Box, Container, Typography } from "@material-ui/core";
import LinkBar from "../components/linkbar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    // Not sure how to apply theme.tsx to both markdown content
    // and material ui components yet, so duplicating the core styles here
    // WARNING: must keep these in sync with src/gatsby-theme-material-ui-top-layout/theme.tsx
    fontFamily: ["Karla", "sans-serif"].join(","),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    "& h1": {
      fontSize: 40,
      fontWeight: `bold`,
      color: `#B35509`,
    },
    "& h2": {
      fontSize: 32,
      fontWeight: `bold`,
    },
    "& h3": {
      fontSize: 25,
      fontWeight: `bold`,
    },
    "& h4": {
      fontSize: 20,
      fontWeight: `bold`,
    },
    "& a": {
      color: `#0999CD`,
    },
    "& table": {
      borderCollapse: "collapse",
      "& th": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
      "& td": {
        border: "1px solid #999",
        padding: "0.5rem",
      },
    },
  },
}));

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const classes = useStyles();
  return (
    <>
      {frontmatter.linkBar ? <LinkBar selected={frontmatter.title} /> : null}
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h1" gutterBottom>
            {frontmatter.title}
          </Typography>
          <hr />
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Box>
      </Container>
    </>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        linkBar
        slug
        title
      }
    }
  }
`;
